export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Home',
        route: 'dashboard',
        icon: 'HomeIcon',
      },
      {
        title: 'Orders',
        route: 'orders',
        icon: 'ArchiveIcon',
      },
      {
        title: 'Categories',
        route: 'categories',
        icon: 'ListIcon',
      },
      {
        title: 'Shoppers',
        route: 'shoppers',
        icon: 'UsersIcon',
      },
      {
        title: 'Coupons',
        route: 'coupons',
        icon: 'CreditCardIcon',
      },
      {
        title: 'Products',
        route: 'products',
        icon: 'CodesandboxIcon',
      },
      {
        title: 'Featured Products',
        route: 'featured-products',
        icon: 'CodesandboxIcon',
      },
      {
        title: 'Pages',
        route: 'pages',
        icon: 'FileIcon',
      },
      {
        title: 'Daily Deals',
        route: 'daily-deals',
        icon: 'AirplayIcon',
      },
      {
        title: 'Packages',
        route: 'packages',
        icon: 'PackageIcon',
      },
    ],
  },
]
